var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header" },
    [
      _c(
        "div",
        { staticClass: "page-header__top" },
        [
          _c(
            "div",
            { staticClass: "header-title" },
            [
              _vm.showBackIcon
                ? _c(
                    "a",
                    {
                      staticClass: "back-icon",
                      on: { click: _vm.handleBackTo }
                    },
                    [_c("a-icon", { attrs: { type: "arrow-left" } })],
                    1
                  )
                : _vm._e(),
              _vm.showStatus
                ? _c(
                    "a-tag",
                    { attrs: { color: _vm.status ? "green" : "red" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.status ? _vm.$t("Public") : _vm.$t("Private")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.title
                ? _c("h6", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                : _vm._t("title")
            ],
            2
          ),
          _vm._t("extra")
        ],
        2
      ),
      _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }