//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: { type: String, default: '' },
    showBackIcon: { type: Boolean, default: false },
    showStatus: { type: Boolean, default: false },
    status: { type: Boolean, default: false }
  },

  methods: {
    handleBackTo() {
      this.$emit('back');
    }
  }
};
